// ログインフォーム

<template lang="pug">
el-container.border-t-4.border-teal-500
  el-main
    el-card.md__mt-10.mx-auto.max-w-xl
      img.mx-auto.w-24.md__w-32(src='@/assets/logo.png')
      h1.mt-2.text-lg.font-bold.tracking-widest.text-center 名古屋市私立幼稚園協会
      el-form.mt-2(
        @submit.native.prevent='login'
      )
        el-alert.error-message(v-show='error', type='error') {{ error }}
        el-form-item(label='ログインID', :error='error', :show-message='false')
          el-input(v-model='loginName', clearable)
        el-form-item(label='パスワード', :error='error', :show-message='false')
          el-input(v-model='password', show-password)
          //- p.forgat-password
          //-   | パスワードをお忘れの方は
          //-   a(href='#') こちら
          //-   | 。
        p.mt-6.p-2.text-sm.bg-gray-200.text-gray-800.rounded
          | 以前の会員園向けサイトで使用していたIDとパスワードでログインできます。
          | また、研修の申し込みもこちらからお願いします。
        el-form-item.mt-6.text-center
          el-button.px-20(
            type='primary',
            native-type='submit'
          ) ログイン
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PublicLogin',
  data() {
    return {
      loginName: '',
      password: '',
      error: null,
    }
  },
  computed: {
    ...mapGetters({
      role: 'app/role',
    }),
  },
  methods: {
    ...mapActions({
      _login: 'app/login',
      setError: 'app/setError',
    }),
    async login() {
      try {
        const result = await this._login({
          loginName: this.loginName,
          password: this.password,
        })
        if (!result) {
          this.error = 'ログインIDまたはパスワードが正しくありません。'
          return
        }
        // 一応エラークリア
        this.error = null
        // ロールに応じて飛ばす先を決める
        if (this.role === 'org' || this.role === 'staff') {
          this.$router.push({ name: 'MemberTop' })
        } else if (this.role === 'admin') {
          this.$router.push({ name: 'AdminTop' })
        }
      } catch (error) {
        this.setError(error)
      }
    },
  },
}
</script>
